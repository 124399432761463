import React from 'react'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const navigation = [
    { name: 'About', href: '#about', current: false },
    { name: 'Experience', href: '#experience', current: false },
    { name: 'Education', href: '#education', current: false },
    { name: 'Skill & Project', href: '#skills-project', current: false },
    { name: 'Contact', href: '#contact', current: false },
  ];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function Navbar() {
  return (
    <Disclosure as="nav" className="">
      <div className="w-full">
        <div className="relative flex h-16 items-center justify-between">
          <div className="flex items-center justify-center flex-row gap-2">
            <svg version="1.1" viewBox="0 0 1148 1292" width="42" height="42" xmlns="http://www.w3.org/2000/svg">
                <path transform="translate(758,107)" d="m0 0h18l20 3 11 4 12 7 7 7 7 11 6 18 2 13 1 17v95l-4 75-5 61-3 30-6 37-3 45-2 43-4 34-5 27-7 19-9 15-11 12-11 11-15 10-25 12-7 2 6 21 8 16 8 11 7 7 13 9 16 8 21 6 43 10 22 6 19 7 16 7 14 9 11 9 8 7 7 8 9 12 10 17 12 24 12 31 10 28 17 52 15 55 1 8-3 5-4 3-12-2-15 11-15 10-14 9-25 15-18 11-16 9-11 8-9 3-14 7-32 13-26 10-32 10-28 8-30 7-39 7-51 5-32 2h-48l-47-4-31-4-41-7-54-13-35-11-9-2-17-8-18-8-34-16-27-15-25-15-22-13-27-18-5-1-2 1h-7l-4-4-1-8 7-24 11-33 13-34 13-30 13-28 13-24 14-21 13-16 8-8 11-9 14-11 19-12 12-7 28-13 24-9 42-15 27-10 20-10 15-9 9-9 8-14 6-20 2-9 5-5 4-3v-17l-1-26-3-17-5-10-11-15-13-17-8-12-3-2-7-2-5-6-8-15v-2l-15 4-8 1h-15l-13-3-11-6-8-7-9-13-7-15-4-16-3-25v-13l2-14 6-19v-29l-3-55-6-66v-17l2-15 5-15 5-10 10-13 10-10 15-10 13-6 9-4-1-2-18-4-13-2-5-5-1-6 4-5 7-1 24 4 24 7 9 2 13-17 9-9 14-11 10-7 21-11 21-7 18-3h25l36 4 23 3 13 1h29l26-4 37-6z" fill="#4AA8FA"/>
                <path transform="translate(715,297)" d="m0 0 7 4 10 6 10 4 9 2h18l12-5 3 1 6 17 6 29 5 48 2 32v99l-2 35-3 31-5 27-5 15-8 16-11 13-11 9-13 8-11 5-24 8-23 4-9 1h-32l-29-4-26-7-17-6h-8l-3 6v6l5 5 20 8 26 7 19 3 10 1h36l24-3h5l6 21 9 17 10 14 8 9 12 9 16 8 15 6-2 4-13 11-11 8-16 10-16 8-15 6-21 6-22 4-10 1h-32l-23-3-25-6-19-7-16-8-14-8-17-13-13-12-13-13-9-11-1-3 8-7 7-7 8-13 5-11 5-19v-11l-4-4-4-3v-17l-1-26-3-17-5-10-11-15-13-17-8-12-3-3 6-2v-8l-8-20-5-12-4-6h-9l-15 9-13 3-11-1-12-5-9-8-8-15-4-17-2-14v-28l4-15 6-9 7-6 8-3h13l13 4 5 3 8 1 2 2 4 13 3 3 8 1 8-3 10-8 7-10 5-11 4-14 1-7 1-51 3-12 4-8 4-5 9-4 4-1h32l20 2h43l17-3 29-8 23-8 19-7 2 1-10 10-14 11-10 7-12 7-4 4 1 7 5 5h7l16-9 12-9 13-11 10-10 9-11z" fill="#F4B488"/>
                <path transform="translate(758,107)" d="m0 0h18l20 3 11 4 12 7 7 7 7 11 6 18 2 13 1 17v95l-4 75-5 61-3 30-6 37-3 45-2 43-4 34-5 27-7 19-9 15-11 12-11 11-15 10-25 12-7 2 6 21 8 16 8 11 7 7 13 9 16 8 21 6 43 10 22 6 19 7 16 7 14 9 11 9 8 7 7 8 9 12 10 17 12 24 12 31 10 28 17 52 15 55 1 8-3 5-4 3-6-1-6-2-3-15-22-77-12-36-12-30-11-26-10-19-8-12-12-13-9-8-10-7-16-9-18-7-26-8-34-8-4 2-17 17-13 10-19 12-15 8-23 9-19 6-25 5-19 2h-27l-25-3-25-6-15-5-21-9-17-9-18-12-11-9-24-24-10-14-5 1-15 7-49 18-28 10-24 10-24 11-11 6-12 8-11 8-12 11-14 14-8 11-10 15-9 16-11 22-7 15-8 19-22 59-5 16-8 26-6 4h-7l-4-4-1-8 7-24 11-33 13-34 13-30 13-28 13-24 14-21 13-16 8-8 11-9 14-11 19-12 12-7 28-13 24-9 42-15 27-10 20-10 15-9 9-9 8-14 6-20 2-9 5-5 3-2 5 1 5 5v11l-5 19-8 16-7 10-12 12h-2l8 10 11 12 12 12 14 11 13 9 20 11 24 9 25 6 23 3h32l22-3 25-6 19-7 16-8 14-8 16-12 13-11 1-2-10-3-16-7-12-8-11-10-11-15-8-14-5-12-3-10v-5l-18 3-11 1h-36l-23-3-22-5-21-7-10-5-5-5v-6l4-6 2-1 10 1 16 6 23 6 29 4h32l27-4 15-4 19-7 15-8 11-9 6-5 9-12 8-16 5-17 4-23 3-31 2-35v-99l-2-32-5-48-6-29-6-17-6 2-8 3h-18l-13-3-15-8-7-5-2 1-4 7-15 16-12 11-15 11-16 9h-7l-5-4-2-4 1-7 10-7 12-7 13-10 13-12h-2l-20 7-27 9-22 6-17 3h-43l-20-2h-32l-10 3-5 5-4 6-3 9-1 6-1 51-3 15-5 13-6 10-4 5-10 8-8 3-8-1-4-4-4-13-5-1-7-3-10-4-5-1h-13l-9 4-7 6-5 10-3 12v28l3 19 4 14 8 14 7 6 12 5 11 1 13-3 15-9h9l3 3 9 21 6 15v6l-3 3-2 1h-7l-6-5-9-17v-2l-15 4-8 1h-15l-13-3-11-6-8-7-9-13-7-15-4-16-3-25v-13l2-14 6-19v-29l-3-55-6-66v-17l2-15 5-15 5-10 10-13 10-10 15-10 13-6 9-4-1-2-18-4-13-2-5-5-1-6 4-5 7-1 24 4 24 7 9 2 13-17 9-9 14-11 10-7 21-11 21-7 18-3h25l36 4 23 3 13 1h29l26-4 37-6z" fill="#010102"/>
                <path transform="translate(445,1008)" d="m0 0h9l8 2 12 7 5 5 10 9 13 8 13 4 7 1h18l11-4 16-10 10-9 11-8 7-4 4-1h12l12 6v2l4 1 5 5 10 9 12 7 14 5 7 1h11l16-3 10-6 10-7 11-10 14-8 5-2h12l12 6 4 3v2l4 1 5 6 5 3v2l4 2 15 8 11 2h18l12-3h4l4 11 5 21 1 15 1 1 1 11 2 9 4 8 3 19v6h2v11l-3 9 2 5 3 2-28 12-39 15-32 10-28 8-30 7-39 7-51 5-32 2h-48l-47-4-31-4-41-7-54-13-32-10 2-1 1-6 3-7 3-12 6-12 4-11 2-5 2-8 6-8 5-7 7-22 3-13 2-6h2l3-9 8-18 4-10 1-11 16-15 7-4z" fill="#6BD2FD"/>
                <path transform="translate(289,974)" d="m0 0h19l12 4 17 9 5 4v2l4 2 8 7 4 2v2l14 4 18-4 5-4 5-5 10-8h2v-2l7-5 13-6 8-2h19l12 3 14 7 12 9 8 8 9 7 8 2h11l12-6 14-12 13-9 14-7 9-2h15l10 2 12 5 12 7 8 8 12 9 11 5 9 1 12-6 11-8h2l2-4 9-7 11-6 11-4 5-1h21l13 4 13 8 10 9 10 7v2l5 2 9 4h9l9-2 7-8 11-4 5 2 7 16 3 8 4 16 7 24 4 9 2 9 2 18 2 6 5 23 2 11 4 4 4 1h7l-1 3-16 9-8 6-9 3-3 2h-5l-3-7 3-8 1-11h-2l-4-25-4-8-2-11-1-9-1-1-1-16-7-27-1-4-16 4h-20l-9-2-15-8-4-3v-2l-5-2-5-5v-2l-4-1v-2l-16-8h-12l-14 7-8 6-10 9-18 11-16 3h-11l-10-2-13-5-11-7-11-10-3-2v-2l-4-1v-2l-5-2-7-3h-12l-8 3-11 8-13 11-16 10-11 4h-18l-14-3-9-4-11-7-10-9-7-6-11-6-6-1h-9l-11 5-10 9-8 7-1 11-5 12-11 25h-2l-3 17-3 8-5 16-6 8-6 9-2 9-4 10-9 21-3 12-3 7-11-1-36-16-32-15-3-3-6-4-1-3h2v-3l6-1 6-3 16-3 13 1 14 4 9 4 7 1h3l3-9 5-12h2l1-15 4-10 5-10 7-8 3-10 7-16 6-13 2-6h-20l-12-3-12-6-10-8v-2l-4-2-12-10-7-3-11-2-10 3-9 6-8 6-5 5-7 6-16 8-6 2-16 1-15-2-12-4h-5l2-6h2v-4h2l1-8 5-12 2-8 3 6 16 3 9-1 8-4 10-9 4-4 5-3 10-7 14-6z" fill="#4AA8FA"/>
                <path transform="translate(295,923)" d="m0 0h10l9 4 13 11 4 3v2l4 2 9 7 16 6 5 1h21l15-5 10-6 5-4h2v-2l6-4 8-8 12-6h15l12 6 5 5 10 9 14 9 11 5 6 1h23l14-5 12-7 12-11 10-8 9-4h14l9 4 10 8 12 11 12 7 10 4 5 1h20l15-4 12-6 11-10 12-9 12-6 3-1h11l8 4 11 9 12 11 10 7 14 4 11 1h12l13-3 13-8 13-11 14-10 11-4h8l14 7 5 4v2l4 2 5 5 14 10 12 4 5 5 8 24 5 16v8l-3 1-11-2-13-10-13-11-15-9-14-4h-14l-13 3-9 4-12 7-8 7-11 8-5 6-9 2h-9l-14-7-5-5-8-6-8-7-12-7-13-4h-21l-14 4-10 5-11 8-5 5-14 10-9 4-9-1-14-7-13-11-7-6-15-8-9-3-7-1h-15l-12 3-16 9-11 9-12 10-11 5h-11l-9-3-11-9-7-7-15-10-12-5-9-2h-19l-10 3-18 9v2l-13 10-3 1-1 3-5 4-18 4-7-2-7-3v-2l-5-2-16-14-19-10-10-3h-19l-13 4-14 7-7 6h-3l-2 4-11 9-6 4-10 2-15-2-4-2-1-2 1-9 14-30 5-6 5-2 16-3 14-7 12-10h2l2-4 10-7z" fill="#6BD2FD"/>
                <path transform="translate(806,812)" d="m0 0 20 4 30 8 26 9 17 9 14 10 10 9 7 7 3 7 6 9 3 6 6 11v2l-5-2-14-7-11-3h-15l-10 2-12 5-14 9-14 11-11 7-11 2-9-2-13-10-9-8-10-7-16-7-8-2h-22l-14 5-11 7-13 11-13 10-6 2h-16l-10-6-10-9-13-10-12-7-11-3-12-2-9 1-9 2-12 7-13 10-10 8-11 7-5 2h-12l-11-5-14-11-10-8-16-8-9-3h-20l-14 4-11 6-14 11-15 11-13 4-9-2-11-6-14-12-10-7-13-6-8-3h-21l-14 4-11 6-9 6-2-1 1-4 9-10 10-10 8-6h2v-2l14-10 9-5 7-5 5-2 9 1 5 4v2l4 2 9 6 18 7 6 1h11l14-3 14-7 9-7 12-10 10-6 9-3h9l8 3 8 6 13 10v2l5 2 10 7 10 4 7 1h17l16-2 5-1v-2l9 1 19 6 24 5 19 2h27l26-3 25-6 23-8 20-9 20-12 14-10 13-12z" fill="#6AD1FC"/>
                <path transform="translate(880,1141)" d="m0 0h1v11l-3 9 2 5 3 2-28 12-39 15-32 10-28 8-30 7-39 7-51 5-32 2h-48l-47-4-31-4-41-7-54-13-32-10 2-1 1-6 3-7 3-12h1l1 8 3 1h14l9-4 9-6 8-6 10-8 14-7 15-4h12l13 2 11 5 11 7 11 10 10 7 11 4h11l10-5 11-9 13-10 10-6 16-5h15l12 2 16 7 13 10 10 9 11 7 2 1h9l10-4 11-7 17-14 16-8 10-3 9-1 16 2 11 4 10 5 10 8 10 9 9 6 5 2h9l9-3 4-3 6-2 3-3 4-2 5-4h3l2-4 6-5 2-4z" fill="#4AA8FA"/>
                <path transform="translate(446,1093)" d="m0 0h11l10 4 8 6 10 9 11 8 10 5 12 3h23l13-5 11-7 10-8 13-10 8-4 5-1h10l9 4 12 11 9 7 13 8 6 3 20 3h9l15-4 13-7 9-8 11-9 9-6 8-2h10l8 3 13 12 13 10 15 8 8 2h24l11-3 14-8 5 1 3 10 2 13v8l-4 6-6 5-1 2-5 3-6 4h-3l-1 3-7 3-4 3-8 2h-9l-10-5-15-13-11-8-12-6-12-3-12-1-12 2-15 6-9 6-15 12-12 8-9 3h-9l-10-6-10-9-13-10-16-8-7-2-8-1h-15l-16 5-14 9-11 9-12 9-7 3h-11l-11-4-12-9-13-11-11-6-11-4-9-1h-12l-15 4-14 7-15 12-10 7-8 4-3 1h-16l-2-2v-9l6-14 6-16 4-7 10-5 13-4 12-6 8-7 6-5h2l1-3 11-7z" fill="#6BD2FD"/>
                <path transform="translate(297,1007)" d="m0 0 11 2 7 3 13 11 10 9 12 7 9 3 6 1 20 1-1 6-9 21-6 13-2 7-7 7-4 9-4 10-1 12-1 3h-2l-1 5-7 16-7 1-11-4-7-3-8-2-13-1-16 3-8 4-4-1v3h-2l5 5 5 3-2 1-29-16-25-15-22-13-18-12-10-7 3-1 1-4 3-9 1-8 1-5 3-13 5-12 3-8 5-1 12 4 15 2 16-1 13-5 12-7 7-6 5-5 10-8 9-4z" fill="#4AA8FA"/>
                <path transform="translate(442,1059)" d="m0 0h12l16 3 9 4 10 6 12 9 7 7 10 5 6 2h8l12-5 14-12 3-1v-2l10-7 13-6 9-2 10-1 15 1 10 3 14 9 22 18 8 4 5 1h7l11-4 10-8 13-11 10-6 12-5 13-2h10l16 4 10 5 9 6 5 5 8 7 11 7 8 2h9l11-3v-2l4-1v-2l7-6 3-4 1-8 2 4 1 14 1 1 1 11 2 9 4 8 1 8-2-3-1-3-6 1-12 7-11 3h-24l-10-3-13-7-16-13-10-9-8-3h-10l-10 3-13 10-8 7h-2l-1 3-14 8-12 4-5 1h-9l-20-3-15-8-16-13-9-8-9-4h-10l-9 3-11 7-13 11-16 10-11 4h-23l-12-3-12-6-17-14-8-7-8-4-4-1h-11l-9 4-8 5-5 5-11 9-8 5-23 8-5 2-2 6-2 1 1-5 6-8 5-7 7-22 3-13 2-6h1v5l7-4 3-1v-2l14-10 10-5z" fill="#4AA8FA"/>
                <path transform="translate(447,1177)" d="m0 0h9l12 5 4 3v2l4 2v2l4 2 10 9 11 6 6 3 20 3h10l12-4 13-7 13-11 10-8 12-6h15l8 4 9 7v2l4 2 13 11 12 6 6 2 24 1 14-3 15-8 12-9 8-8 11-6 5-1h12l9 4 6 4 8 10v5h6l-3 2-27 8-38 9-39 7-51 5-32 2h-48l-47-4-31-4-41-7-33-8-1-2 8-2 2-5 12-12 13-9z" fill="#6BD2FD"/>
                <path transform="translate(907,1008)" d="m0 0h7l11 4 10 8 12 11 10 6 13 5 13 2 13 1 4 7 3 12 2 5 3 15v4l3 1-15 11-15 10-14 9-25 15-18 11h-3-8l-6-4-2-5-3-18-4-18-1-1-2-18-1-7-4-9-5-16-6-20v-6l18-12z" fill="#6BD2FD"/>
                <path transform="translate(297,1007)" d="m0 0 11 2 7 3 13 11 10 9 12 7 9 3 6 1 20 1-1 6-9 21-6 13-4 7-3 2-8-4-10-10-2-1v-2l-4-2-14-9-7-3-18-2h-11l-9 2-15 8-10 8-11 9-11 7-10 2-9-2-15-8-10-9-6-5-2-3 1-11 6-18 2-2 7 1 14 4 18 1 12-2 16-8 8-6 5-5 14-11 9-4z" fill="#6BD2FD"/>
                <path transform="translate(458,792)" d="m0 0 4 1 12 16 24 24 15 11 15 9 19 10 15 6-4 1-2-1v2l-5 2-16 2h-17l-10-2-12-6-8-6-11-9-10-8-7-3-3-1h-9l-13 5-11 8-10 9-13 8-10 4-11 2h-11l-12-3-13-6-12-8-5-5-9-1-5 1 5-4 12-4 5-2 10-5 7-3 3-3 16-6 19-7h2v-2l33-12 25-10z" fill="#49A7F9"/>
                <path transform="translate(888,1058)" d="m0 0 2 1v2l13-2h11l10 1 9 3 16 9 5 5 6 5v2l4 2 9 6 12 3 15-3 3 1-1 3-27 18-18 11-30 18-4 3-2-1h-8l-6-4-2-5-3-18-4-18-1-1-2-18-1-7-4-9z" fill="#4AA8FA"/>
                <path transform="translate(806,812)" d="m0 0 20 4 30 8 26 9 17 9 14 10 10 9 7 7-1 2-4-3v-2l-4-2-9-8-13-9-6-1-9 7-9 8-13 9-9 4-8 2h-21l-17-5-11-6-12-11-6-5v-2l-5-1-1-3 11-8 12-11z" fill="#49A7F9"/>
                <path transform="translate(904,974)" d="m0 0h14l14 4 15 9 10 9 13 10 6 3 10 1 1-6h1l2 20 5 15 5 10-1 3-3-6-23-2-12-4-14-8-15-14-9-6-9-3h-7l-10 3-12 8-5 3h-2l-8-20-2-4-4-1-8 2 4-4 9-7 11-7 9-4 9-3z" fill="#4AA8FA"/>
                <path transform="translate(292,1094)" d="m0 0h16l8 4 9 7v2l4 2 11 9 9 6v8l-9 21-7 1-11-4-7-3-8-2-13-1-16 3-8 4-4-1v3h-2l5 5 5 3-2 1-29-16-25-15 3-1 8 2 7-4 13-5 9-6h2l1-3 22-16z" fill="#6BD2FD"/>
                <path transform="translate(662,439)" d="m0 0h9l4 4 2 11 4 20 10 30 11 30 6 18v7l-3 5-5 2-8 1-39 2h-16l-5-4-1-2v-7l3-5 5-2 39-3h6l-2-8-11-29-9-25-4-16-2-12v-12z" fill="#010203"/>
                <path transform="translate(402,1026)" d="m0 0 7 2 3 3 1 7-6 16-13 29-10 24-8 19-6 13-12 31-6 15-4 8-1 1-8-1-8-4 1-9 8-20 11-26 10-25 9-20 6-15 11-26 5-12 6-8z" fill="#010001"/>
                <path transform="translate(862 1e3)" d="m0 0 6 2 4 6 6 17 11 43 6 27 8 44 3 14-5 5-4 3-9 1-3-5-5-32-7-33-10-43-5-16-6-18v-7l4-6z" fill="#010001"/>
                <path transform="translate(588,596)" d="m0 0 9 1 22 8 20 5 15 2 30 1 5 2 3 4 1 5-3 5-4 3-5 1h-25l-18-2-22-5-20-7-10-5-4-6 1-5 4-6z" fill="#020305"/>
                <path transform="translate(902,1093)" d="m0 0h11l12 4 8 5v2l4 2 9 9 4 3 4 5 3 2-30 18-4 3-2-1h-8l-6-4-2-5-3-18-4-18 1-5z" fill="#6BD2FD"/>
                <path transform="translate(294,1143)" d="m0 0 13 1 14 4 9 4 7 1h3l2-6 1 3-7 11-5 10-2 12 1 5-13-5-28-13-15-7-3-3-6-4-1-3h2v-3l6-1 6-3z" fill="#4AA8FA"/>
                <path transform="translate(402,1026)" d="m0 0 7 2 3 3 1 7-6 16-13 29-10 24-8 19-6 13-12 31-6 15-4 8-1 1h-5l3-5 8-22 5-11 6-15 4-11h2l1-5 3-7 6-16 12-22 6-15 7-20 2-2v-8l-3-3-4 1-6 8-3 11-4 9-5 12-5 11-10 26-5 10-9 21-13 32-4 11v7l-5-2 1-9 8-20 11-26 10-25 9-20 6-15 11-26 5-12 6-8z" fill="#0F202D"/>
                <path transform="translate(726,401)" d="m0 0h9l6 4 4 8v27l-2 8-4 5-7 3-7-1-6-4-3-5-1-17 1-19 4-6z" fill="#020204"/>
                <path transform="translate(590,401)" d="m0 0h9l6 4 3 4 1 3v32l-4 7-6 4h-9l-6-4-3-3-1-3v-33l4-8z" fill="#010203"/>
                <path transform="translate(398,1022)" d="m0 0h10l3 1v2h2l1 2v17l-12 27-3 7h-2l-3 17-3 8-5 16-6 8-6 9-2 9-4 10-9 21-3 12-3 7h-5v-3l8-19 6-16 10-25 8-17 8-20 11-25 12-28 1-8-3-5-7-2-6 4-7 14-7 17-8 19-5 12-10 23-10 25-9 21-7 18-2 8-2-3-1-4 2-10 5-10 7-11 2-8 4-10h2l1-15 4-10 5-10 7-8 3-10 7-16 6-13 1-3h2l1-9 6-11z" fill="#4094D9"/>
                <path transform="translate(862 1e3)" d="m0 0 6 2 4 6 6 17 11 43 6 27 8 44 3 14-3 4h-2l-7-39-4-16-3-18-5-26-5-22-5-17-3-11h-10l-1 2v7l5 16 5 22 5 18 3 12 3 9 6 31 3 12 3 18 3 7h-6l-3-5-5-32-7-33-10-43-5-16-6-18v-7l4-6z" fill="#0F202D"/>
                <path transform="translate(864,997)" d="m0 0 5 2 7 16 3 8 4 16 7 24 4 9 2 9 2 18 2 6 5 23 2 11 4 4 4 1h7l-1 3-13 7h-3l-3-14-8-44-7-31-11-42-6-16-4-4-4-1-6 2-3 5v7l10 30 7 30 5 23 6 27 5 32 2 5 2 1-5 3h-5l-3-7 3-8 1-11h-2l-4-25-4-8-2-11-1-9-1-1-1-16-7-27-3-7-3-5-3-12-2-5 2-8 5-7z" fill="#4094D8"/>
                <path transform="translate(806,814)" d="m0 0h11l36 9v2l5 1 1 3-5 5-9 6-6 2h-11l-14-6-10-9-3-8z" fill="#6AD0FC"/>
                <path transform="translate(389,819)" d="m0 0 3 1-21 8-16 6h-2l-1 3-13 6-7 4-5 1-10 4-4 1-1 3-7 5-8 4-11 8-3 1v2l-11 8-15 15-3 4h-2l-2 4-8 15-4 8h-2l1 3-3 5h-2v5l-2 3h-2v6l-4 2-1 4-8 16-13 29-2 9-4 9-2 8h-2v4h-2l-3 11-5 14-3 8-1 9-2 8-2 9-3 6-6 1 3-7 7-22 5-16 25-67 11-25 10-19 7-13 11-18 9-12 9-10 16-16 12-9 15-10 21-11 28-12 29-11z" fill="#4093D7"/>
                <path transform="translate(281,870)" d="m0 0 2 1-13 12-14 14-8 11-10 15-9 16-11 22-7 15-8 19-22 59-5 16-8 26-5 3 2-10 13-35 6-18 1-8 2-6 4-11 2-6 4-10 5-12 8-16 3-7 3-8 4-6 1-3h2l2-5 7-12 4-7h2l1-5 10-16 9-9 14-11z" fill="#0F202D"/>
                <path transform="translate(393,463)" d="m0 0h9l10 4 11 6 6 5 1 3v7l-4 5-2 1h-6l-10-6-8-4-9-3-4-4-1-6 3-5z" fill="#010103"/>
                <path transform="translate(935,874)" d="m0 0 5 5 12 17 6 10 4 10 3 6v5h2l3 9 4 16 8 21 5 14 6 16 9 29 4 14 5 18 4 18v6h-4l-3-15-22-77-12-36-12-30-11-26-10-19-6-9z" fill="#0F202D"/>
                <path transform="translate(409,814)" d="m0 0h5l-3 5-9 10-10 7-10 5-9 1-11-3v-2l-5-1v-3l19-8 14-5h2v-2l14-3z" fill="#6AD1FC"/>
                <path transform="translate(411,1044)" d="m0 0h2l-1 5-8 17-5 12h-2l-3 17-3 8-5 16-6 8-6 9-2 9-4 10-9 21-3 12-3 7h-5v-3l8-19 6-16 10-25 8-17 8-20 11-25 10-24z" fill="#4094D9"/>
                <path transform="translate(879,1033)" d="m0 0h2l7 22 3 10 3 7 2 9 2 18 2 6 5 23 2 11 4 4 4 1h7l-1 3-13 7h-3l-3-14-8-44-7-31-8-30z" fill="#4195DB"/>
                <path transform="translate(367,447)" d="m0 0 1 2-4 15v28l3 19 4 14 8 14 7 6 12 5 11 1 13-3 15-9h9l3 3 9 21 6 15v6l-3 3h-2l2-4-1-8-8-19-5-12-1-2h-9l-10 7-7 3-8 1h-13l-11-4-8-5-5-6v-2h-2l-5-10-3-8-3-19-1-10v-13l1-15 4-13z" fill="#0F202D"/>
                <path transform="translate(389,819)" d="m0 0 3 1-21 8-16 6h-2l-1 3-13 6-7 4-5 1-10 4-4 1-1 3-7 5-8 4-11 8-3 1v2l-11 8-15 15-3 4-3-1 12-13 15-15 12-9 15-10 21-11 28-12 29-11z" fill="#449CE6"/>
                <path transform="translate(798,372)" d="m0 0h1l4 42 1 13 1 42v20l-1 55-3 42-3 25-3 21-3 7-4 8-4 6-1-2 7-17 4-16 3-18 3-31 2-35v-99l-2-32-2-21z" fill="#0F202D"/>
                <path transform="translate(974,960)" d="m0 0 2 3 12 36 12 42 11 40v7h-3l-2-9-3-14-3-9-2-9-5-11-4-12-2-20-5-14-6-19-2-6z" fill="#4195DB"/>
                <path transform="translate(459,790)" d="m0 0 4 1 7 8 1 3 4 2v2h2v3h2v2h2l8 11 8 8 5 3 4 1v2l4 2 10 8 15 9 22 11-3 1-23-11-21-13-14-11-25-25-10-14-5 1-15 7-16 6-3-1 25-11 7-3z" fill="#0F202D"/>
                <path transform="translate(458,792)" d="m0 0 4 1 12 16 24 24 15 11 15 9 19 10 15 6-4 1-14-5-9-2v-2l-6-1v-2l-5-2-5-3v-2h-3v-2l-4-2-13-10-12-10-2-1v-2h-3l-1-3-4-2-2-2-4-5-1-4h-2l-3-7-3-3-2-4z" fill="#4093D7"/>
                <path transform="translate(678,524)" d="m0 0 3 4 5 14v6l-48 4-4 3-1 6 3 5h20l29-1h15v1l-8 1-39 2h-16l-5-4-1-2v-7l3-5 5-2 39-3h6l-2-8-4-11z" fill="#0F202D"/>
                <path transform="translate(251,903)" d="m0 0v3l-9 16-4 8h-2l1 3-3 5h-2v5l-2 3h-2v6l-4 2-1 4-9 18h-3l3-9 18-36 6-9 7-11z" fill="#4094D8"/>
                <path transform="translate(864,997)" d="m0 0 5 2 7 16 3 8 1 10-2-2-5-17-5-10-6-2-6 2-3 5v7l9 26-1 6-2-4-3-7-3-5-3-12-2-5 2-8 5-7z" fill="#4093D8"/>
                <path transform="translate(379,821)" d="m0 0 4 1-34 13-29 13-15 8-9 6-2-1 11-8 11-7 12-6 11-5 15-5 10-5z" fill="#0F202D"/>
                <path transform="translate(930,870)" d="m0 0 5 4 8 13 11 21 11 27 9 22-1 3-3-6v-2l-2-1-3-11h-2l-4-13-5-11v-3h-2l-3-9-6-10-6-12-7-10z" fill="#4093D7"/>
                <path transform="translate(437,539)" d="m0 0h9l3 3 9 21 6 15v6l-3 3h-2l2-4-1-8-8-19-5-12-1-2h-9l-10 7-7 3-11 1 1-2 14-4z" fill="#0F202D"/>
                <path transform="translate(389,819)" d="m0 0 3 1-21 8-16 6h-2l-1 3-13 6-7 4-5 1-9 2 4-4 29-13 35-13z" fill="#4094DA"/>
                <path transform="translate(594,617)" d="m0 0 5 1 11 4 15 4 17 3 11 1h16l20-1-3 3-5 1h-25l-18-2-22-5-20-7z" fill="#0F202D"/>
                <path transform="translate(458,793)" d="m0 0 3 1 3 6-1 4-7 1h-15v-3l16-8z" fill="#6BD2FD"/>
                <path transform="translate(180,1057)" d="m0 0 1 2-3 13-2 11-2 9-3 6-6 1 3-7 7-22 3-10z" fill="#4093D7"/>
                <path transform="translate(318,848)" d="m0 0m-2 1h2l-1 3-4 1-1 3-7 5-18 9-2-1 5-4 15-10z" fill="#4093D7"/>
                <path transform="translate(884,834)" d="m0 0 5 1 10 4 13 8v3l4 1 7 8 3 3-1 2-9-9-14-10-16-9z" fill="#0F202D"/>
                <path transform="translate(862,827)" d="m0 0 9 2 15 6 13 7-3 1-9-3-2-1v-2l-6-1-6-3-9-1z" fill="#4093D7"/>
                <path transform="translate(411,1044)" d="m0 0h2l-1 5-8 17-5 12-2-4 11-25z" fill="#439AE2"/>
                <path transform="translate(770,840)" d="m0 0 2 3-17 11-15 8-3-1 3-2v-2l9-3 4-2 5-4 7-3z" fill="#0F202D"/>
                <path transform="translate(902,845)" d="m0 0 4 2 12 9 12 12-1 2-4-3v-2l-4-2-9-8-8-6z" fill="#449CE6"/>
                <path transform="translate(930,870)" d="m0 0 5 4 8 13 6 11v3h-2l-12-22-5-7z" fill="#4399E2"/>
                <path transform="translate(452,554)" d="m0 0h2l9 21 1 9-3 3h-2l2-4-1-8-8-19z" fill="#0E1F2C"/>
                <path transform="translate(974,960)" d="m0 0 2 3 11 33v3h-2l-8-26-3-8z" fill="#4298DF"/>
                <path transform="translate(388,477)" d="m0 0 4 2 13 4 8 5 6 4h7l-2 2h-6l-10-6-8-4-9-3z" fill="#0F202D"/>
                <path transform="translate(211,976)" d="m0 0h2l-1 5-8 18-2-1 1-6 6-14z" fill="#4298E0"/>
                <path transform="translate(456,793)" d="m0 0 2 1-4 3-11 5-14 5-4 2h-5l2-2 26-10z" fill="#4195DB"/>
                <path transform="translate(813,814)" d="m0 0 9 1 16 4v1h-12l-15-4z" fill="#47A1EF"/>
                <path transform="translate(906,1139)" d="m0 0 5 4 4 1h7l-1 3-5 3-4-2-5-4z" fill="#4BA9F7"/>
                <path transform="translate(861,1046)" d="m0 0h2l4 16-1 6-2-4-3-13z" fill="#4399E2"/>
                <path transform="translate(187,1037)" d="m0 0 1 3-6 18-2-1 2-9 4-10z" fill="#4399E2"/>
                <path transform="translate(879,1033)" d="m0 0h2l6 19v3h-2l-6-20z" fill="#4298DF"/>
                <path transform="translate(311,854)" d="m0 0 2 1-8 6-8 4h-3l5-4z" fill="#4AA8FA"/>
                <path transform="translate(416,470)" d="m0 0 5 2 8 6 1 3v7h-1l-2-8-8-7z" fill="#0F202D"/>
                <path transform="translate(824,817)" d="m0 0 10 1 4 2h-12l-2-1z" fill="#47A2F1"/>
            </svg>
            <div className='text-[rgb(46,175,225)] font-bold text-xl text-mix-color'>
                Balaramakrishna
            </div>
          </div>

          <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
            <DisclosureButton className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              <XMarkIcon className="hidden h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>

          <div className="hidden sm:block sm:ml-6">
            <div className="flex space-x-4">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.current ? 'text-black' : 'hover-text-mix-color',
                    'rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>

      <DisclosurePanel className="sm:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2">
          {navigation.map((item) => (
            <DisclosureButton
              key={item.name}
              as="a"
              href={item.href}
              className={classNames(
                item.current ? 'text-black' : 'hover-text-mix-color',
                'block rounded-md px-3 py-2 text-base font-medium'
              )}
            >
              {item.name}
            </DisclosureButton>
          ))}
        </div>
      </DisclosurePanel>
    </Disclosure>
  )
}

export default Navbar